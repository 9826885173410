.selectedCard {
  background-color: #e0f2fe !important;
}
.card {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  padding: 12px;
  padding-left: 22px;
  box-shadow: 0px 6px 16px 0px #3a46541a;
  border-radius: 6px;
  .leftBar {
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    color: #334c5b;
    font-family: 'Gilroy';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
}

.layoutContainer {
  height: 600px;
  .title {
    color: #334c5b;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .description {
    color: #5e7283;
    font-family: Gilroy;
    font-size: 15px;
    font-style: normal;
    line-height: 130%; /* 19.5px */
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    height: 100%;
    .actionButton {
      border-radius: 4px;
      padding: 4px 8px;
      background-color: #409ba6;
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        color: var(--white, #fff);
        font-family: Gilroy;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
      }
    }
  }
}

.cronJobDetail {
  .executionId {
    font-weight: bold;
    font-size: 12px;
  }
  .label {
    font-weight: 600;
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
  .detail {
    margin-top: 1.2rem;
    font-size: 12px;
    color: #108ee9;
    font-weight: 600;
  }
  .detailLabel {
    width: 100px;
  }
  .bdage {
    color: black;
    font-size: 12px;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    text-align: center;
    text-transform: capitalize;
    background-color: #acd7d5;
  }

  .resultWrapper {
    width: 100%;
    height: 300px;
    overflow: auto;
  }
}

.success {
  background-color: #acd7d5;
}
.failed {
  background-color: #e3bec2;
}
.pending_status {
  background-color: #f2e8d1;
}
.schedule_status {
  background-color: #cecaca;
}
