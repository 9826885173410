.jobTitle {
  color: #54565b;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.rowContainer {
  overflow-y: auto;
  height: 100%;
}
