.inputContainer {
  height: 100%;

  .input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid rgb(13, 188, 13);
    border-radius: 3px;
    outline: none;
    cursor: pointer;
  }

  .input:checked {
    background-color: rgb(13, 188, 13);
    position: relative;
  }

  .input:checked::before {
    content: '\2714';
    font-size: 16px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.scheduleType {
  margin-top: 10px;
  margin-bottom: 10px;
  span {
    line-height: 20px;
    text-transform: capitalize;
  }
}
.edit {
  color: #54a0e5;
  text-decoration: underline;
  cursor: pointer;
}
