.input {
  border: 1px solid hsl(0, 0%, 80%);
  background-color: #ffffff;
  width: 100%;
  font-size: 12px;
  padding: 8px 6px;
}

.description {
  color: #5e7283;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  line-height: 130%; /* 19.5px */
}
